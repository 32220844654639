import { isTransactionFormat } from '@/app/utils/helper'
import { SearchStatusType } from '@/types/enum'

export default defineNuxtRouteMiddleware((to) => {
  const appStore = useAppStore()

  if (to.name === 'transaction-transaction') {
    if (!to.params.transaction) {
      appStore.$patch({
        searchStatus: SearchStatusType.TransactionHashInvalid,
        searchParam: to.params.transaction
      })

      return navigateTo('/404')
    }

    const routeTransactionParam = Array.isArray(to.params.transaction)
      ? to.params.transaction[0]
      : to.params.transaction

    if (!isTransactionFormat(routeTransactionParam)) {
      appStore.$patch({
        searchStatus: SearchStatusType.TransactionHashInvalid,
        searchParam: routeTransactionParam
      })

      return navigateTo('/404')
    }
  }
})
